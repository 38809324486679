import { TemplateCodeInsertionError } from '@powerednow/shared/modules/errors';
import textUtils from '@powerednow/shared/modules/utilities/textUtils';
import * as _ from 'lodash';
import {
    calculateLocalDateTime, format as formatDate, parse, patterns,
} from '@powerednow/shared/modules/utilities/date';

const Ext = require('extjs-custom');
const templateContext = require('context');
const moment = require('moment');

function patchXTemplateCompiler(Namespace) {
    function testOpeningSequence(text) {
        if (/{\[|{%/.test(text)) {
            throw new Error();
        }
    }

    function testClosingSequence(text) {
        if (/]}|%}/.test(text)) {
            throw new Error();
        }
    }
    Namespace.XTemplateCompiler.prototype.doExpr = _.wrap(Ext.XTemplateCompiler.prototype.doExpr, function (originalFn, expr: string) {
        testOpeningSequence(expr);
        originalFn.call(this, expr);
    });
    Namespace.XTemplateCompiler.prototype.doEval = _.wrap(Ext.XTemplateCompiler.prototype.doEval, function (originalFn, text: string) {
        testOpeningSequence(text);
        originalFn.call(this, text);
    });
    Namespace.XTemplateCompiler.prototype.doText = _.wrap(Ext.XTemplateCompiler.prototype.doText, function (originalFn, text: string) {
        testClosingSequence(text);
        originalFn.call(this, text);
    });
}

patchXTemplateCompiler(Ext);
const currencyDefaults = {
    decimal: 2,
    decimalsep: '.',
    thousandsep: ',',
    symbol: '£',
    symbol_before: true,
};

export default function (
    languageClass: { LSync: (code: string, defaultValue: string, params?: object, languageCode?: string) => string },
    currencyOptions?: Parameters<typeof textUtils['formatMoney']>[1],
    dateFormat?: string,
) {
    (function extendExtFormatter() {
        Ext.util.Format.formatMoney = (money, currencyOptionsDirect) => textUtils.formatMoney(money, { ...currencyDefaults, ...currencyOptions, ...currencyOptionsDirect });
        // todo: borrow proper formatDateTime feature from client code
        // Consider fixing this todo now or standardise format function in server and client. The client uses Ext's format, where m is month, and i is minutes, where in moment M is the month, m is minutes and s is the seconds.
        Ext.util.Format.formatDateTime = (dateTime, format = dateFormat || 'DD/MM/YYYY') => moment(dateTime).format(format.replace('d', 'DD').replace('m', 'MM').replace('H', 'HH').replace('i', 'mm'));
        // Unfortunately the duplicated name is required, because 'formatDateTime' works differently in the fronted then here. It puts the time (the version above not by default) so i cant use the 'formatDateTime' in the templates.
        Ext.util.Format.formatDate = Ext.util.Format.formatDateTime;
        Ext.util.Format.formatLocaleDateTime = (dateTime, values, dateTimeFormat?) => {
            const utcDate = parse(dateTime, patterns.ISO8601Long);
            const localDateTime = calculateLocalDateTime(utcDate, values.city, values.countryCode);
            return formatDate(localDateTime, dateTimeFormat || values.dateTimeFormat);
        };
        // todo: borrow markdown feature from client code
        Ext.util.Format.markdown = value => value;
        Ext.util.Format.multiLine = value => {
            if (value) {
                return value.replace(/\n/g, '<br/>');
            }
            return '';
        };
    }());

    return {
        /**
         * Render template
         *
         * @param templateSource
         * @param data
         * @param languageCode
         * @param templatePath
         */
        renderBySource(templateSource: string, data: object, languageCode?: string, templatePath?: string): string {
            const self = this;

            const templateGlobals = '{%L = this.L || L; quoteLabelPostfix = values.quoteLabelPostfix || this.quoteLabelPostfix || (typeof quoteLabelPostfix !== \'undefined\' ? quoteLabelPostfix : \'\');%}';
            return (function () {
                const extendedSource = `${templateGlobals}${templateSource}`.replace(/\\(?!')/g, '&#92;').replace(/\\'/g, '&#x27');
                const tpl = new Ext.XTemplate(
                    extendedSource,
                    {
                        L(code, defaultValue, params = null) {
                            const languageTemplate = languageClass.LSync(code, defaultValue, null, languageCode);
                            if (params) {
                                return self.renderBySource(languageTemplate, params);
                            }
                            return languageTemplate;
                        },
                    },
                );
                let result = 'Template Uncompiled';
                try {
                    result = tpl.apply(data);
                } catch (e) {
                    throw new TemplateCodeInsertionError(templatePath ?? templateSource, e);
                }
                return result;
            }());
        },

        /**
         * Load source of given template path and render
         *
         * @param templatePath
         * @param data
         * @param languageCode
         * @returns {*}
         */
        async renderByPath(templatePath: string, data: object, languageCode?: string): Promise<string> {
            const self = this;
            return self.getTemplateSourceByPath(templatePath)
                .then(source => self.renderBySource(source, data, languageCode, templatePath));
        },

        /**
         * Get template file source by it's path
         *
         * @param path
         * @returns {Promise}
         */
        async getTemplateSourceByPath(path: string) {
            return templateContext.getTemplateContent(path.replace('@powerednow/shared/', ''));
        },
    };
}
